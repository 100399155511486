import { UserContext } from 'context/UserContext';
import { lazy, useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
// import HeaderLoadable from 'ui-component/lazy/LoadableHeader';
// import SidebarLoadable from 'ui-component/lazy/LoadableSidebar';
// material-ui
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// project imports
import { SET_MENU } from 'store/actions';
import { drawerWidth } from 'store/constant';
import config from 'globalConfig';
import ErrorScreen from 'ui-component/ErrorScreen';
import { IconBroadcastOff } from '@tabler/icons-react';
import useOnlineStatus from 'hooks/isOnline';
import Header from './Header';
import Sidebar from './Sidebar';

// const Header = HeaderLoadable(lazy(() => import('./Header')));
// const Sidebar = SidebarLoadable(lazy(() => import('./Sidebar')));

// assets

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    background: theme.palette.grey[50],
    margin: 0,
    marginTop: '3.5rem',
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: 0,
    overflowX: 'hidden',
    // minHeight: 'calc(100vh - 88px)',
    // background: theme.palette.grey[100],
    // width: `calc(100% - ${drawerWidth}px)`, // height: `calc(100% - 72px)`,
    padding: '0',
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `100%`,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: '3.5rem',
            marginRight: 0
            // maxWidth: `1366px`
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '3.5rem',
            marginRight: 0
            // maxWidth: `1366px`
            // width: `100%`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '3.5rem',
            marginRight: 0
            // maxWidth: `1366px`,
            // padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0 // maxWidth: '100vw',
            // width: `100%`,
            // padding: '16px',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0, // maxWidth: '100vw',
            // width: `100%`,
            // padding: '16px',
            marginRight: 0
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: drawerWidth,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: `100%`
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'));
    const [userContext, setUserContext] = useContext(UserContext);
    const [miniNavbar, setMiniNavbar] = useState(false);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { adminid } = useParams();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: false });
    }, [dispatch]);
    // UserVerify();
    const isOnline = useOnlineStatus();
    if (userContext && userContext?.user?.identity) {
        return (
            <Box sx={{ display: 'flex' }}>
                {/* <Box
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    border: '1px solid red',

                    height: '34px',
                    width: '100%',
                    // background: "rgba(0,0,0,0.85)",
                    WebkitAppRegion: 'drag',
                    zIndex: 100000000
                }}
            /> */}
                <CssBaseline />
                {/* header */}
                <AppBar
                    enableColorOnDark
                    position="fixed"
                    color="inherit"
                    elevation={0}
                    sx={{
                        boxShadow: '0px 8x 16px 0px rgba(0,0,0,1)',
                        zIndex: 10000,

                        top: 0,
                        left: 0,
                        height: '3.5rem',
                        // bgcolor: theme.palette.primary[200],
                        transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                    }}
                    open={leftDrawerOpened}
                >
                    <Toolbar
                        sx={{
                            bgcolor: theme.palette.grey[50],
                            // height: '3.5rem',

                            // bgcolor: route.pathname === '/' ? theme.palette.primary.main : theme.palette.background.paper,
                            // color: theme.palette.primary.main,
                            // bgcolor: isRouteHome ? theme.palette.primary.main : theme.palette.background.paper,
                            borderBottom: `1px solid ${theme.palette.primary.light}`,
                            borderTop: `1px solid ${theme.palette.primary.light}`,
                            transition: 'all 100ms ease 0s',
                            padding: '0 !important'
                        }}
                    >
                        {/* {userContext && userContext?.user && ( */}
                        <Header
                            param={adminid}
                            context={userContext}
                            setContext={setUserContext}
                            handleLeftDrawerToggle={handleLeftDrawerToggle}
                        />
                        {/* // )} */}
                    </Toolbar>
                </AppBar>

                {/* drawer */}
                {/* {userContext && userContext?.user &&  */}
                <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
                {/* } */}

                {/* main content */}
                <Main open={leftDrawerOpened}>
                    {!isOnline && (
                        <ErrorScreen
                            translucent
                            fixed
                            icon={<IconBroadcastOff color={theme.palette.error.main} stroke={2} size={72} />}
                            title="Sei offline"
                            subtitle="Per utilizzare quest'applicazione devi essere online. Controlla la tua connessione e riprova."
                            errbg={theme.palette.grey[50]}
                            loadingfg={theme.palette.primary[400]}
                            errfg={theme.palette.error.main}
                            loadingbg={theme.palette.grey[50]}
                            details={false}
                            err={{
                                message: 'test'
                            }}
                            // top="3rem"
                        />
                    )}
                    <Outlet context={[userContext, setUserContext]} />
                </Main>
                {/* <Customization /> */}
            </Box>
        );
    }

    return <></>;
};
export default MainLayout;
