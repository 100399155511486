/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography(theme) {
    return {
        // fontFamily: theme?.customization?.fontFamily,
        // !! GLOBAL
        fontFamily: `'Inter', sans-serif`,
        body: {
            fontFamily: `'Inter', sans-serif`,
            color: theme.primaryMain
        },
        h1: {
            fontFamily: `'Inter', sans-serif`,

            fontSize: '2.5rem',
            fontWeight: 700,
            color: theme.primaryMain
        },
        h2: {
            fontFamily: `'Inter', sans-serif`,

            fontSize: '2rem',
            fontWeight: 700
        },
        h6: {
            fontFamily: `'Inter', sans-serif`,

            fontSize: '0.875rem',
            fontWeight: 700
        },
        marquee: {
            fontFamily: `'Inter', sans-serif`,

            // fontSize: '4.5rem',
            fontWeight: 200
        },
        // !! HEADER
        headerItem: {
            fontFamily: `'Inter', sans-serif`,

            fontSize: '0.875rem',
            fontWeight: 400,
            color: theme.background
        },
        headerHint: {
            fontFamily: `'Inter', sans-serif`,

            fontSize: '0.75rem',
            fontWeight: 400,
            color: theme.background
        },
        headerLogo: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1.25rem',
            // letterSpacing: '0.1rem',
            fontWeight: 500
            // color: theme.primaryMain
            // textTransform: 'uppercase'
        },
        // !! FOOTER
        footerLogo: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '2rem',
            letterSpacing: '0.2rem',
            fontWeight: 700,
            color: theme.background,
            textTransform: 'uppercase'
        },
        // !! DRAWER

        menuItem: {
            fontFamily: `'Inter', sans-serif`,
            padding: '0.5rem 0.625rem',
            '&:hover': {
                background: 'secondary.200'
            },
            fontSize: '0.875rem'
        },

        frontendMenuItem: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '2rem',
            fontWeight: 400,
            color: theme.background
        },
        // !! PROFILE POPPER

        popperListItem: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1rem',
            fontWeight: 400,
            color: theme.primaryMain
        },
        // !! BACKEND DRAWER

        backendMenuItem: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1rem',
            fontWeight: 600
            // color: theme.primaryMain
        },
        backendMenuItemSelected: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1rem',
            fontWeight: 500
            // color: theme.primaryMain
        },
        // ** ROUTES
        // !! !! PRODUCT
        herotitle: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '2.5rem',
            lineHeight: '2.75rem',
            fontWeight: 600,
            color: theme.background
        },
        herosubtitle: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1.25rem',
            fontWeight: 300,
            color: theme.background
        },
        productBody: {
            fontFamily: `'Inter', sans-serif`,
            fontWeight: 300,
            lineHeight: '150%',
            whiteSpace: 'pre-line'
        },
        imageBody: {
            fontFamily: `'Inter', sans-serif`,
            fontWeight: 300,
            lineHeight: '150%',
            whiteSpace: 'pre-line'
        },
        modulesTitle: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1.25rem',
            fontWeight: 700,
            lineHeight: '150%',
            color: theme.primaryMain,
            textTransform: 'uppercase'
        },
        blockSubtitle: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1.25rem',
            fontWeight: 700,
            // lineHeight: '150%',
            color: theme.primaryMain,
            textTransform: 'uppercase',
            borderLeft: `1px solid ${theme.primaryMain}`
        },
        moduleTopic: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '.875rem',
            fontWeight: 600
            // lineHeight: '150%',
            // color: theme.primaryMain,
            // textTransform: 'uppercase'
        },
        moduleSubtitle: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '.875rem',
            fontWeight: 400
            // lineHeight: '150%',
            // color: theme.primaryMain,
            // textTransform: 'uppercase'
        },
        callToAction: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: '150%',
            color: theme.primaryMain
            // textTransform: 'uppercase'
        },
        accordionTitle: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1.25rem',
            fontWeight: 600,
            lineHeight: '150%',
            color: theme.primaryMain
            // textTransform: 'uppercase'
        },
        accordionBody: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1.25rem',
            fontWeight: 300,
            lineHeight: '150%'
            // color: theme.primaryMain
            // textTransform: 'uppercase'
        },
        topicDetailBody: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1rem',
            fontWeight: 300,
            lineHeight: '150%',
            color: theme.background
        },
        // !! !! HOME
        carouselHeader: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1.25rem',
            fontWeight: 600,
            lineHeight: '1.875rem',
            color: theme.background
            // textTransform: 'uppercase'
        },
        carouselBody: {
            fontFamily: `'Inter', sans-serif`,

            fontSize: '0.875rem',
            fontWeight: 300,
            color: theme.background
        },
        // !! !! MODULE
        relatedModules: {
            fontFamily: `'Inter', sans-serif`,
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: '150%',
            color: theme.primaryMain
            // textTransform: 'uppercase'
        },
        // !! !! CASE HISTORY
        refName: {
            fontFamily: `'Inter', sans-serif`,
            fontWeight: 500,
            color: theme.background
        },
        refAddress: {
            fontFamily: `'Inter', sans-serif`,
            fontWeight: 300,
            color: theme.background
        }
    };
}
