// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import App from './App';
import * as serviceWorker from 'serviceWorker';
import { store } from 'store';

import 'assets/scss/style.scss';
import { UserProvider } from 'context/UserContext';
import config from 'globalConfig';

// const opts = {
//     addTypename: false,

//     typePolicies: {
//         Query: {
//             fields: {
//                 // allUsers: {
//                 //     keyArgs: false,
//                 //     merge(existing = [], incoming) {
//                 //         return [...existing, ...incoming];
//                 //     }
//                 // },
//                 // allClienti: {
//                 //     keyArgs: false,
//                 //     merge(existing = [], incoming) {
//                 //         return [...existing, ...incoming];
//                 //     }
//                 // },
//                 // clienteById: {
//                 //     keyArgs: ['id', 'Id_Cliente', 'ud_cliente']
//                 // }
//                 // allProducts: {
//                 //     keyArgs: false,
//                 //     merge(existing = [], incoming) {
//                 //         return [...existing, ...incoming];
//                 //     }
//                 // },
//                 // allOrders: {
//                 //     keyArgs: false,
//                 //     merge(existing = [], incoming) {
//                 //         return [...existing, ...incoming];
//                 //     }
//                 // },
//                 // allInvoices: {
//                 //     keyArgs: false,
//                 //     merge(existing = [], incoming) {
//                 //         return [...existing, ...incoming];
//                 //     }
//                 // },
//                 // allPayments: {
//                 //     keyArgs: false,
//                 //     merge(existing = [], incoming) {
//                 //         return [...existing, ...incoming];
//                 //     }
//                 // },
//                 // allSettings: {
//                 //     keyArgs: false,
//                 //     merge(existing = [], incoming) {
//                 //         return [...existing, ...incoming];
//                 //     }
//                 // },
//                 // allCategories: {
//                 //     keyArgs: false,
//                 //     merge(existing = [], incoming) {
//                 //         return [...existing, ...incoming];
//                 //     }
//                 // },
//                 // allSubCategories: {
//                 //     keyArgs: false,
//                 //     merge(existing = [], incoming) {
//                 //         return [...existing, ...incoming];
//                 //     }
//                 // }
//             }
//         }
//     }
// };

const isDev = process.env.NODE_ENV === 'development';
const client = new ApolloClient({
    uri: isDev ? `${config.remoteDevServer}/gql` : `${process.env.PUBLIC_URL}/gql`,
    cache: new InMemoryCache(),
    resave: false,
    saveUninitialized: true,
    cookie: { secure: true },
    // link: HttpLink,
    connectToDevTools: isDev
});
// ==============================|| REACT DOM RENDER  ||============================== //

const root = createRoot(document.getElementById('root'));
root.render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            {/* <CookiesProvider> */}
            <UserProvider>
                <BrowserRouter basename={isDev ? process.env.PUBLIC_URL : config.productionBaseName}>
                    <App />
                </BrowserRouter>
            </UserProvider>
            {/* </CookiesProvider> */}
        </Provider>
    </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register();
