// material-ui
import { Box, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DividerButton from './DividerButton';
import { useContext } from 'react';
import { UserContext } from 'context/UserContext';

// styles

// ==============================|| LOADER ||============================== //
const BreadCrumbs = ({ drawerOpen }) => {
    const theme = useTheme();
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const [userContext, setUserContext] = useContext(UserContext);

    return (
        <Box
            sx={{
                display: drawerOpen && !matchUpSm ? 'none' : 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                flexWrap: 'nowrap',
                overflow: 'hidden',
                maxWidth: 'calc(100vw - 3.5rem)'
            }}
        >
            {matchUpSm &&
                userContext?.route?.map((item, index) => (
                    <DividerButton
                        active={item.active}
                        label={item?.title}
                        subtitle={item?.subtitle}
                        index={userContext?.route?.length - index - 1}
                        route={item?.location}
                        key={index}
                    />
                ))}
            {!matchUpSm &&
                userContext?.route?.map(
                    (item, index) =>
                        (index === userContext?.route?.length - 1 || index === userContext?.route?.length - 2) && (
                            <DividerButton
                                active={item.active}
                                label={item?.title}
                                subtitle={item?.subtitle}
                                index={userContext?.route?.length - index - 1}
                                route={item?.location}
                                key={index}
                            />
                        )

                    // }
                )}
        </Box>
    );
};

BreadCrumbs.propTypes = {
    drawerOpen: PropTypes.bool
};

export default BreadCrumbs;
