import PropTypes from 'prop-types';

// material-ui
import { Box, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// assets

import { IconArrowLeft, IconMenu } from '@tabler/icons-react';
import config from 'globalConfig';
import { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { drawerWidth } from 'store/constant';
import BreadCrumbs from 'ui-component/BreadCrumbs';
import Loadable from 'ui-component/lazy/Loadable';
// import Loader from 'ui-component/Loader';

const ProfileSection = Loadable(lazy(() => import('./ProfileSection')));

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, context, setContext, param }) => {
    const theme = useTheme();

    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    // const isOnline = useOnlineStatus();
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    // go back through useloaction
    const navigate = useNavigate();
    const location = useLocation();
    const route = location.pathname;
    // console.log('isOnline', isOnline);

    // check if running in pwa mode
    const isPWA = useMediaQuery('(display-mode: standalone)');
    // console.log('isPWA', isPWA);
    return (
        <Box
            sx={{
                // width: drawerWidth - 30,
                width: '100%',
                // maxWidth: '100vw',
                margin: 0,
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start'
            }}
        >
            <Box
                sx={{
                    // display: matchUpSm ? 'flex' : 'none',
                    transition: 'all 0.1s ease-in-out',
                    // apptitle has width 0 when drawer is closed
                    // width: leftDrawerOpened ? '100%' : 0,
                    overflowX: 'hidden',
                    display: leftDrawerOpened ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: '3.5rem',
                    width: `calc(${drawerWidth}px - 3.5rem)`
                    // background: theme.palette.primary.light
                }}
            >
                {context && <ProfileSection param={param} context={context} setContext={setContext} />}
            </Box>
            <Button
                disableElevation
                disableRipple
                disableTouchRipple
                disableFocusRipple
                onClick={() => navigate(-1)}
                color="inherit"
                variant="text"
                sx={{
                    display: (isPWA && route !== '/' && !leftDrawerOpened) || (isPWA && !matchUpSm && !leftDrawerOpened) ? 'flex' : 'none',

                    borderRadius: config.broderRadiusSm,
                    borderRight: `1px solid ${theme.palette.primary.light}`,
                    aspectRatio: '1',
                    height: '3.5rem',
                    width: '3.5rem',
                    minWidth: '3.5rem',
                    m: 0,
                    // overflow: 'hidden',
                    color: theme.palette.primary.main
                }}
            >
                <IconArrowLeft size={24} stroke={2} />
            </Button>
            {context && context?.user?.identity?.Ruolo && (
                <Button
                    disableElevation
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    onClick={handleLeftDrawerToggle}
                    color="inherit"
                    variant="text"
                    sx={{
                        // display: route === '/' || !matchUpSm ? 'flex' : 'none',

                        borderRadius: config.broderRadiusSm,
                        borderRight: `1px solid ${theme.palette.primary.light}`,
                        aspectRatio: '1',
                        height: '3.5rem',
                        width: '3.5rem',
                        minWidth: '3.5rem',
                        m: 0,
                        // overflow: 'hidden',
                        color: theme.palette.primary.main
                    }}
                >
                    <IconMenu size={24} stroke={2} />
                </Button>
            )}
            <BreadCrumbs drawerOpen={leftDrawerOpened} />
        </Box>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func,
    context: PropTypes.object,
    setContext: PropTypes.func,
    param: PropTypes.string
};

export default Header;
