// material-ui
import { Box, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import config from 'globalConfig';

// styles

// ==============================|| LOADER ||============================== //
const ViewLoader = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                width: '100%',
                height: 'calc(100vh - 3.5rem)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                backgroundColor: theme.palette.grey[50]
            }}
        >
            <LinearProgress
                sx={{
                    width: '250px',
                    height: '1rem',
                    borderRadius: config.borderRadiusMd,
                    background: theme.palette.grey[10],
                    ' & .MuiLinearProgress-bar': {
                        borderRadius: config.borderRadiusMd,
                        '&.MuiLinearProgress-barColorPrimary': {
                            background: theme.palette.grey[100]
                        },
                        '&.MuiLinearProgress-bar1Indeterminate': {
                            background: theme.palette.primary.light
                        },
                        '&.MuiLinearProgress-bar2Indeterminate': {
                            background: theme.palette.grey[100]
                        }
                    }
                }}
                variant="indeterminate"
            />
        </Box>
    );
};

export default ViewLoader;
