import PropTypes from 'prop-types';

// material-ui
import { Box, Drawer, Typography, useMediaQuery } from '@mui/material';
import { useTheme, Theme } from '@mui/material/styles';
// third-party
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import { drawerWidth } from 'store/constant';
import Loadable from 'ui-component/lazy/Loadable';
import { lazy, useState } from 'react';
import Logo from 'ui-component/Logo';
import config from 'globalConfig';
import MenuList from './MenuList';
import { UserContext } from 'context/UserContext';

// const LogoSection = Loadable(lazy(() => import('../LogoSection')));
// const MenuList = Loadable(lazy(() => import('./MenuList')));

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('sm'));

    const drawer = <MenuList />;

    const container = window !== undefined ? () => window.document.body : undefined;
    const widthSelector = () => {
        if (drawerOpen && matchUpMd) {
            return drawerWidth;
        }
        if (drawerOpen && !matchUpMd) {
            return drawerWidth;
        }
        if (!drawerOpen && matchUpMd) {
            return '3.5rem';
        }
        if (!drawerOpen && !matchUpMd) {
            return 0;
        }
        return 0;
    };
    return (
        // <Box
        //     component="nav"
        //     sx={{
        //         // flexShrink: { md: 0 },
        //         width: matchUpMd ? drawerWidth : 'auto',
        //         flexShrink: 0
        //         // whiteSpace: 'nowrap'
        //         // boxSizing: 'border-box'
        //         // ...(drawerOpen && {
        //         //     ...openedMixin(theme),
        //         //     '& .MuiDrawer-paper': openedMixin(theme)
        //         // }),
        //         // ...(!drawerOpen && {
        //         //     ...closedMixin(theme),
        //         //     '& .MuiDrawer-paper': closedMixin(theme)
        //         // })
        //     }}
        //     aria-label="mailbox folders"
        // >
        <Drawer
            container={container}
            variant={matchUpMd ? 'permanent' : 'temporary'}
            anchor="left"
            open={drawerOpen}
            onClose={drawerToggle}
            sx={{
                // width: drawerOpen ? drawerWidth : 48,
                // flexShrink: 0,

                // height: '100vh',
                '&>.MuiModal-backdrop ': {
                    background: `linear-gradient(${theme.palette.grey[50]}, rgba(231, 240, 252,0.3))`,
                    backdropFilter: 'blur(1rem)'
                },
                '& .MuiDrawer-paper': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    height: '100vh',
                    boxShadow: 'none',
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: '100ms'
                    }),
                    overflowX: 'hidden',
                    width: widthSelector,
                    background: theme.palette.grey[50],
                    color: theme.palette.text.primary,
                    // borderRight: 'none',
                    borderRight: `1px solid ${theme.palette.primary.light}`,
                    [theme.breakpoints.up('md')]: {
                        // height: 'calc(100vh - 88px)',
                        top: '3.5rem',
                        height: 'calc(100% - 3.5rem)',
                        maxHeight: 'calc(100% - 3.5rem)'
                        // height: 'calc(100vh - 88px)'
                    },
                    [theme.breakpoints.down('md')]: {
                        height: 'calc(100% - 3.5rem)',
                        maxHeight: 'calc(100% - 3.5rem)',
                        minHeight: 'none',
                        top: '3.5rem'

                        // height: 'calc(100vh - 88px)'
                    }
                },
                // when open
                ...(drawerOpen && {
                    transition: theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: '100ms'
                    })
                })
            }}
            ModalProps={{ keepMounted: true }}
            color="inherit"
        >
            <Box>{drawer}</Box>
            {drawerOpen && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '3.5rem',
                        background: theme.palette.grey[10],
                        overflowX: 'hidden'
                        // px: '.rem'
                        // borderTop: `1px solid ${theme.palette.primary.light}`
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',

                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '2.25rem',
                            minHeight: '2.25rem',
                            maxHeight: '2.25rem',
                            pt: '0px',
                            ml: 0,
                            pl: 0,
                            mr: '0.5rem'
                            // border: `1px solid ${theme.palette.primary.light}`
                            // background: theme.palette.primary.light,
                            // borderRadius: config.borderRadiusMd
                        }}
                    >
                        <Logo fgColor={theme.palette.grey[200]} logoSize={0.8} />
                    </Box>
                    <Typography
                        component="span"
                        sx={{
                            mr: '0.5rem',
                            // width: '100%',
                            // ml: 'rem',
                            color: theme.palette.grey[300],
                            fontWeight: 400,
                            fontSize: '0.875rem',
                            // ellipsis
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'
                        }}
                    >
                        RadGuardian {config.appVersion}
                    </Typography>
                </Box>
            )}
        </Drawer>
        // </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;
