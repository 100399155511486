import { useRoutes } from 'react-router-dom';

import { AdminRoutes, AuthRoutes, WorkerRoutes } from './RouteStructure';

import { UserContext } from 'context/UserContext';
import config from 'globalConfig';
import { useContext, useEffect, useState } from 'react';

export function Routes() {
    const [ctx, setCtx] = useContext(UserContext);
    const [role, setRole] = useState(ctx.user?.identity?.Ruolo);
    useEffect(() => {
        setRole(ctx.user?.identity?.Ruolo);
    }, [ctx, role]);
    let routes = [];

    if (role?.interno && role?.livello === 0 && role?.cd_ruolo === 'AMX') {
        //! ADMIN
        routes = [AdminRoutes, AuthRoutes];
    } else if (
        !role?.interno &&
        // role?.livello < 6 &&
        (role?.cd_ruolo === 'EDR' ||
            role?.cd_ruolo === 'MDA' ||
            role?.cd_ruolo === 'DTL' ||
            role?.cd_ruolo === 'SGR' ||
            role?.cd_ruolo === 'RGD')
    ) {
        //! MID LEVEL
        routes = [AdminRoutes, AuthRoutes];
    } else if (!role?.interno && role?.livello === 6 && role?.cd_ruolo === 'LAV') {
        //! LAVORATORE
        routes = [WorkerRoutes, AuthRoutes];
    } else {
        routes = [AuthRoutes];
    }

    return useRoutes(routes, config.basename);
}
