// material-ui
import { Box, LinearProgress, Skeleton } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import config from 'globalConfig';

// styles

// ==============================|| LOADER ||============================== //
const Loader = () => {
    const theme = useTheme();
    const LoaderWrapper = styled('div')({
        width: '100%',
        height: '2rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    });
    return (
        <LoaderWrapper>
            <Skeleton
                variant="rectangular"
                width="100%"
                height="2rem"
                sx={{
                    backgroundColor: theme.palette.grey.lightest
                }}
            />
        </LoaderWrapper>

        // <LinearProgress
        //     sx={{
        //         // width: '250px',
        //         height: '1rem',
        //         borderRadius: config.borderRadiusMd,
        //         maxWidth: '180px',
        //         background: theme.palette.grey[10],
        //         ' & .MuiLinearProgress-bar': {
        //             borderRadius: config.borderRadiusMd,
        //             '&.MuiLinearProgress-barColorPrimary': {
        //                 background: theme.palette.grey[100]
        //             },
        //             '&.MuiLinearProgress-bar1Indeterminate': {
        //                 background: theme.palette.primary.light
        //             },
        //             '&.MuiLinearProgress-bar2Indeterminate': {
        //                 background: theme.palette.grey[100]
        //             }
        //         }
        //     }}
        //     variant="indeterminate"
        // />
    );
};

export default Loader;
