import { useSelector } from 'react-redux';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { Routes } from './routes';
// import loggr from 'utils/consolr';
// routing;

// defaultTheme
import themes from 'themes';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import reportWebVitals from 'reportWebVitals';
// import { Cookies, CookiesProvider, withCookies } from 'react-cookie';

// ==============================|| APP ||============================== //

const App = () => {
    const theme = useTheme();
    // if (theme && theme.palette && theme.palette.primary && theme.palette.grey && theme.palette.secondary) loggr(theme);

    const customization = useSelector((state) => state.customization);
    // const [cookies, setCookie] = useCookies();
    useEffect(() => {
        const handleContextmenu = (e) => {
            e.preventDefault();
        };
        document.addEventListener('contextmenu', handleContextmenu);
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu);
        };
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    {/* <CookiesProvider> */}
                    <Routes />
                    {/* </CookiesProvider> */}
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

reportWebVitals(console.log);

// App.propTypes = {
//     cookies: PropTypes.instanceOf(Cookies).isRequired
// };
// export default withCookies(App);
export default App;
