import { Suspense } from 'react';

// project imports
import ViewLoader from './ViewLoader';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const LoadableView = (Component) => (props) =>
    (
        <Suspense fallback={<ViewLoader />}>
            <Component {...props} />
        </Suspense>
    );

export default LoadableView;
