// material-ui
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ bgColor, fgColor, accentColor, logoSize }) => {
    const theme = useTheme();

    return (
        <svg
            style={{ width: logoSize ? `calc(24px * ${logoSize})` : '24px' }}
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            viewBox="0 0 23 20"
        >
            <path
                fill={fgColor}
                d="M18.78,10.9C22,8.33,23.71,5.84,23,4.57c-.63-1.1-2.95-1.05-6-.09a4.52,4.52,0,0,0-3.84-2.14H10.87A4.48,4.48,0,0,0,7,4.48c-3.06-1-5.37-1-6,.09C.29,5.84,2,8.33,5.22,10.9,2,13.48.29,16,1,17.24c.58,1,2.61,1.05,5.33.29v3.23a.9.9,0,0,0,.9.9h3.05a.9.9,0,0,0,.91-.9v-5h1.55v5a.9.9,0,0,0,.9.9h3a.9.9,0,0,0,.91-.9V17.52c2.72.77,4.76.73,5.34-.28C23.71,16,22,13.48,18.78,10.9Zm2.6-4.76h0Zm-.56-1v0Zm-18.2,1h0Zm.56-1v0ZM2.62,15.67h0Zm.56,1v0Zm3.18-2.52v1c-.46.14-.89.26-1.3.36a10.14,10.14,0,0,1-1.36.23,10.87,10.87,0,0,1,.89-1.06A19.51,19.51,0,0,1,6.36,13Zm0-7.29v2A18.19,18.19,0,0,1,4.59,7.17,9.8,9.8,0,0,1,3.7,6.11a10.37,10.37,0,0,1,1.36.24c.41.09.84.21,1.3.36Zm8.22,3.78a.57.57,0,0,1-.29.5l-2,1.16a.6.6,0,0,1-.58,0l-2-1.16a.57.57,0,0,1-.29-.5V8.31a.57.57,0,0,1,.29-.5l2-1.16a.6.6,0,0,1,.58,0l2,1.16a.57.57,0,0,1,.29.5Zm3-3.91q.7-.21,1.32-.36a10.37,10.37,0,0,1,1.36-.24,9.8,9.8,0,0,1-.89,1.06,19.74,19.74,0,0,1-1.78,1.67v-2A.62.62,0,0,0,17.62,6.71Zm1.32,8.75c-.41-.1-.85-.22-1.31-.37v-1h0V13a21.29,21.29,0,0,1,1.78,1.66,10.87,10.87,0,0,1,.89,1.06A10.14,10.14,0,0,1,18.94,15.46Zm2.44.21h0Zm-.56,1v0Z"
                transform="translate(-0.86 -2.34)"
            />
        </svg>
    );
};
Logo.propTypes = {
    bgColor: PropTypes.string,
    fgColor: PropTypes.string,
    accentColor: PropTypes.string,
    logoSize: PropTypes?.number
};
export default Logo;
