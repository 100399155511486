// material-ui
import { CircularProgress, Typography, styled, useMediaQuery, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { IconFaceIdError } from '@tabler/icons-react';
import config from 'globalConfig';

import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const MotionGrid = styled(motion(Box))(({ theme }) => ({}));

// styles

const ErrorScreen = ({
    icon = null,
    err = null,
    translucent = false,
    fixed = false,
    title = null,
    subtitle = null,
    details = true,
    loading = false,
    errbg = null,
    loadingbg = null,
    errfg = null,
    loadingfg = null
}) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const bgHandler = () => {
        if (loading) {
            return loadingbg || theme.palette.primary.main;
        }
        if (err) {
            return errbg || theme.palette.grey[50];
        }
        return theme.palette.grey[50];
    };
    const fgHandler = () => {
        if (loading) {
            return loadingfg || theme.palette.primary.light;
        }
        if (err) {
            return errfg || theme.palette.error.main;
        }
        return theme.palette.error.main;
    };
    return (
        <Box
            sx={{
                zIndex: 10000,
                transition: theme.transitions.create('all', {
                    easing: theme.transitions.easing.easeInOut,
                    duration: theme.transitions.duration.standard
                }),

                background: translucent ? `linear-gradient(rgba(231, 240, 252,0.8), rgba(231, 240, 252,0.2))` : bgHandler(),
                // opacity: '0.6 !important',
                backdropFilter: translucent ? 'blur(2rem)' : 'none',
                position: fixed ? 'fixed' : 'relative',
                top: 0,
                left: 0,
                width: '100%',
                height: !translucent ? `calc(100vh - 3.5rem)` : '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <MotionGrid
                initial={{
                    opacity: 0
                    // y: 8
                }}
                animate={{
                    opacity: 1
                    // y: 0
                }}
                transition={{ duration: 0.3 }}
                sx={{
                    display: 'flex',
                    flexDirection: matchUpMd ? 'row' : 'column',
                    alignItems: matchUpMd ? 'flex-start' : 'center',
                    justifyContent: 'flex-start',
                    maxWidth: matchUpMd ? '40%' : '90vw',
                    background: err ? theme.palette.background.paper : 'none',
                    borderRadius: config.borderRadiusMd,
                    p: 2
                }}
            >
                {loading && (
                    <CircularProgress
                        thickness={5}
                        size={48}
                        sx={{
                            color: fgHandler()
                            // minWidth: '3rem',
                            // minHeight: '3rem'
                        }}
                    />
                )}
                <Box sx={{ width: '4rem', height: '4rem' }}>
                    {err && icon && icon ? icon : <IconFaceIdError color={theme.palette.error.main} stroke={2} size={72} />}
                </Box>
                {err && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            mt: matchUpMd ? '.5rem' : '1rem',
                            ml: matchUpMd ? '1rem' : 0
                        }}
                    >
                        <Typography
                            variant="h5"
                            color={fgHandler()}
                            sx={{ mb: 2, fontWeight: 500, textAlign: matchUpMd ? 'left' : 'center' }}
                        >
                            {title || 'Errore nel recuperare la voce'}
                        </Typography>

                        <Typography variant="h6" color={fgHandler} sx={{ fontSize: '1rem', fontWeight: 400 }}>
                            {subtitle || 'Errore completo:'}
                        </Typography>
                        {details && (
                            <Typography
                                variant="h6"
                                color={fgHandler}
                                sx={{
                                    mt: '.5rem',
                                    fontSize: '1rem',
                                    fontFamily: 'monospace',
                                    background: theme.palette.grey[50],
                                    borderRadius: config.borderRadiusSm,
                                    p: '.5rem 1rem'
                                }}
                            >
                                {err?.message}
                            </Typography>
                        )}
                    </Box>
                )}
            </MotionGrid>
        </Box>
    );
};

ErrorScreen.propTypes = {
    err: PropTypes.object,
    loading: PropTypes.bool,
    top: PropTypes?.string,
    left: PropTypes?.string,
    bottom: PropTypes?.string,
    right: PropTypes?.string,
    errbg: PropTypes?.string,
    loadingbg: PropTypes?.string,
    errfg: PropTypes?.string,
    loadingfg: PropTypes?.string,
    details: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    icon: PropTypes.element,
    translucent: PropTypes.bool,
    fixed: PropTypes.bool
};

export default ErrorScreen;
