// ==============================|| FRONTEND MENU ITEMS ||============================== //
import {
    IconAddressBook,
    IconAtom,
    IconBuildingFactory2,
    IconGauge,
    IconHome,
    IconPackages,
    IconSettingsBolt,
    IconStack2
} from '@tabler/icons-react';

const backendPages = [
    {
        id: 'worker-tools',
        title: 'Worker',
        type: 'group',
        accessLevel: [6],
        internal: false,
        children: [
            {
                accessLevel: [6],
                id: 'home',
                title: 'Anagrafica',
                icon: IconBuildingFactory2,
                type: 'item',
                url: '',
                internal: false,
                breadcrumbs: false
            },
            {
                accessLevel: [6],
                id: 'assegnazioni',
                title: 'Assegnazioni',
                type: 'item',
                icon: IconAtom,
                url: 'assegnazioni',
                internal: false,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'partner-tools',
        title: 'Partners',
        type: 'group',
        accessLevel: [1, 4],
        internal: false,
        children: [
            {
                accessLevel: [1, 4],
                id: 'dashboard',
                title: 'Home',
                icon: IconHome,
                type: 'item',
                url: '',
                internal: false,
                breadcrumbs: false
            },
            {
                accessLevel: [1, 4],
                id: 'clienti',
                title: 'Clienti',
                type: 'item',
                icon: IconAddressBook,
                url: 'clienti',
                internal: false,
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'admin-tools',
        title: 'Admin',
        type: 'group',
        accessLevel: [0],
        internal: true,
        children: [
            {
                accessLevel: [0],
                id: 'dashboard',
                title: 'Home',
                icon: IconHome,
                type: 'item',
                url: '',
                internal: true,

                breadcrumbs: false
            },
            {
                accessLevel: [0],
                id: 'clienti',
                title: 'Clienti',
                type: 'item',
                icon: IconAddressBook,
                url: 'clienti',
                internal: true,

                breadcrumbs: false
            },
            {
                accessLevel: [0],
                id: 'magazzini',
                title: 'Magazzini',
                type: 'item',
                icon: IconPackages,
                url: 'magazzini',
                internal: true,

                breadcrumbs: false
                // disabled: true
            },
            {
                accessLevel: [0],
                id: 'dosimetri',
                title: 'Dosimetri',
                type: 'item',
                url: 'dosimetri',
                icon: IconGauge,
                internal: true,

                breadcrumbs: false
                // disabled: true
            },
            {
                accessLevel: [0],
                id: 'processi',
                title: 'Processi',
                type: 'item',
                url: 'processi',
                icon: IconStack2,
                internal: true,

                // disabled: true,
                breadcrumbs: false
            },
            {
                accessLevel: [0],
                id: 'gestione',
                title: 'Gestione',
                type: 'item',
                url: 'gestione',
                icon: IconSettingsBolt,
                internal: true,

                // disabled: true,
                breadcrumbs: false
            }
        ]
    }
];

export { backendPages };
