import PropTypes from 'prop-types';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import { UserContext } from 'context/UserContext';
import config from 'globalConfig';
import { MENU_OPEN, SET_MENU } from 'store/actions';
import { drawerWidth } from 'store/constant';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const customization = useSelector((state) => state.customization);
    const [userContext, setUserContext] = useContext(UserContext);
    const [role, setRole] = useState(userContext.user?.identity?.Ruolo);
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const Icon = item.icon;
    const itemIcon = item?.icon ? (
        <Icon
            stroke={2}
            // stroke={customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 2 : 2}
            size={24}
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                color:
                    customization.isOpen.findIndex((id) => id === item?.id) > -1
                        ? theme.palette.background.paper
                        : theme.palette.primary.main
            }}
        />
    ) : (
        <Icon
            stroke={2}
            sx={{
                width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={`${config.basename}${item.url}`} target={itemTarget} />)
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
        dispatch({ type: SET_MENU, opened: false });
    };

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        // eslint-disable-next-line
    }, []);
    const fgHandler = (disabled, selected) => {
        if (selected) {
            return theme.palette.background.paper;
        }
        if (disabled) {
            return theme.palette.grey[500];
        }
        return theme.palette.primary.main;
    };
    const route = useLocation();

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            disableRipple
            disableTouchRipple
            sx={{
                display: item.accessLevel.includes(role?.livello) && role?.interno === item?.internal ? 'flex' : 'none',
                // px: '2rem',
                width: drawerWidth,
                height: '3.5rem',
                borderRadius: `0`,
                borderBottom: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light}`,
                // '&:first-of-type': {
                //     borderTop: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light}`
                // },
                borderLeft: 0,
                borderRight: 0,
                // borderTop: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100]}`,
                // selected
                transition: 'background 0.3s ease-in-out',

                '&.Mui-disabled': {
                    opacity: 1,
                    pointerEvents: 'none',
                    background: theme.palette.grey[100],
                    '&>.MuiListItemIcon-root': {
                        color: theme.palette.grey[500]
                    }
                },
                '&.Mui-selected': {
                    background: theme.palette.primary.light,
                    '&>.MuiListItemIcon-root': {
                        color: theme.palette.background.paper
                    },
                    color: theme.palette.background.paper,
                    opacity: 1,
                    borderLeft: 0,
                    borderRight: 0,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                        opacity: 1
                    }
                },
                '&:hover': {
                    background: theme.palette.primary.light,
                    opacity: 1,
                    '&>.MuiListItemIcon-root': {
                        color: theme.palette.primary.main
                    }
                },

                // my: '.25rem',
                alignItems: 'center',
                opacity: 1,
                color:
                    route.pathname.split('/').findIndex((id) => id === item.id) > -1
                        ? theme.palette.background.paper
                        : theme.palette.primary.main,
                background:
                    route.pathname.split('/').findIndex((id) => id === item.id) > -1 ? theme.palette.background.paper : 'transparent'
                // backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                // py: level > 1 ? 1 : 1.25,
                // pl: `${level * 24}px`
            }}
            selected={
                // first sublevel of route must match item.id
                route.pathname.split('/').findIndex((id) => id === item.id) > -1
            }
            onClick={() => itemHandler(item.id)}
        >
            <ListItemIcon
                sx={{
                    '&:hover': {
                        color: theme.palette.background.paper
                    },
                    my: 'auto',
                    mr: 2,

                    color:
                        customization.isOpen.findIndex((id) => id === item.id) > -1
                            ? theme.palette.primary[200]
                            : theme.palette.primary.main
                }}
            >
                {itemIcon}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        sx={{
                            fontSize: '1rem',
                            // color:
                            color: fgHandler(item.disabled, customization.isOpen.findIndex((id) => id === item.id) > -1),
                            fontWeight: customization.isOpen.findIndex((id) => id === item.id) > -1 ? '600 !important' : '500 !important'
                            // fontWeight: '700 !important'
                        }}
                    >
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption &&
                    // matchUpSm &&
                    leftDrawerOpened && (
                        <Typography
                            variant="caption"
                            sx={{
                                ...theme.typography.subMenuCaption,
                                color:
                                    customization.isOpen.findIndex((id) => id === item.id) > -1
                                        ? theme.palette.background.paper
                                        : theme.palette.primary.main,
                                fontWeight: customization.isOpen.findIndex((id) => id === item.id) > -1 ? 600 : 400,
                                transition: 'all 0.6s ease-in-out'
                            }}
                            display="block"
                            gutterBottom
                        >
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    sx={{
                        // ml: 'auto',
                        p: 0,
                        '& .MuiChip-label': {
                            ...theme.typography.caption,
                            color: theme.palette.primary.light,
                            px: 1,
                            fontWeight: 600
                        },
                        '& .MuiChip-avatar': {
                            width: 18,
                            height: 18,
                            overflow: 'hidden',
                            // ml: 0.5,
                            // mr: 0.25,
                            background: 'none'
                        },
                        backgroundColor: item.chip.variant === 'primary' ? theme.palette.primary.light : item.chip.variant,
                        // mr: 1
                        minWidth: 'auto',
                        textTransform: 'uppercase',
                        fontSize: '0.75rem',
                        height: '1.5rem',
                        width: 'auto',
                        borderRadius: '0.5rem'
                    }}
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
