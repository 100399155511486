// material-ui
import { Box, Button, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import config from 'globalConfig';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const DividerButton = ({ index, label, subtitle, active, route }) => {
    const theme = useTheme();
    const [bg, setBg] = useState(theme.palette.grey[50]);
    const [hover, setHover] = useState(false);
    const [fg, setFg] = useState(theme.palette.primary.main);
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();
    const handleMouseEnter = () => {
        if (!active) {
            setHover(true);
            setBg(theme.palette.grey[10]);
            setFg(theme.palette.primary.main);
        }
    };
    const handleMouseLeave = () => {
        if (!active) {
            setHover(false);
            setBg(theme.palette.grey[50]);
            setFg(theme.palette.primary.main);
        }
    };
    const widthHandler = (matchUpSm, active) => {
        if (active && matchUpSm) {
            return '100%';
        }
        if (!active && matchUpSm) {
            return '180px';
        }
        if (active && !matchUpSm) {
            // return 'max-width';
            return '100%';
        }
        if (!active && !matchUpSm) {
            return '64px';
        }
        return '180px';
    };
    // sync with active prop to change bg color
    useEffect(() => {
        if (active) {
            setBg(theme.palette.grey[50]);
            setFg(theme.palette.primary.main);
        } else {
            setBg('none');
            setFg(theme.palette.primary.main);
        }
    }, [active, theme.palette.grey[50], theme.palette.primary.main]);
    if (
        !label ||
        (!subtitle && !label) ||
        // if containsthe word 'undefined' or 'null'
        label?.indexOf('undefined') > -1 ||
        label?.indexOf('null') > -1 ||
        subtitle?.indexOf('undefined') > -1 ||
        subtitle?.indexOf('null') > -1
    )
        return (
            <Skeleton
                variant="rectangular"
                width={100}
                height={24}
                sx={{
                    mr: 2,
                    my: 1,
                    ml: 2,
                    borderRadius: config.borderRadiusMd,
                    bgcolor: theme.palette.grey[100]
                }}
            />
        );

    return (
        <Button
            onClick={() => {
                if (active) return;
                if (route) navigate(route);
            }}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            disableElevation
            // dis
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            // disabled={!active}
            sx={{
                pointerEvents: active ? 'none' : 'auto',
                zIndex: index,
                minWidth: 'unset',
                transition: 'all .1s ease-in-out !important',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'stretch',
                pr: 0,
                maxWidth: 'unset',
                whiteSpace: 'nowrap',
                height: '3.5rem',
                m: 0,

                '&:hover': {
                    // transition: 'all .1s ease-in-out',

                    background: 'none !important',
                    opacity: 1,
                    '& div > div': {
                        // transition: 'all .1s ease-in-out',

                        background: !active
                            ? `linear-gradient(-90deg, ${theme.palette.grey[10]} 0%, ${theme.palette.grey[10]} 50%, rgba(0,0,0,0) 100%)`
                            : 'none'
                    }
                }
            }}
        >
            <Box
                sx={{
                    background: bg,
                    borderTop: `1px solid ${theme.palette.primary.light}`,
                    borderBottom: `1px solid ${theme.palette.primary.light}`,
                    display: 'flex',
                    //! il glifo è largo 12px, margino in negativo e paddo per creare la giusta sovrapposizione
                    pl: '28px',
                    mr: 0,
                    pr: active ? '24px' : '12px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    // transition: 'all .1s ease-in-out',
                    width: '100%',
                    maxWidth: widthHandler(matchUpSm, active),
                    // maxWidth: active || hover ? 'max-width' : '180px',
                    minWidth: 'calc(100% + 12px)',
                    ml: '-24px',

                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',

                    // transition: 'all .1s ease-in-out',
                    position: 'relative',
                    height: '3.5rem'
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        // bottom: 0,
                        width: '12px',
                        background:
                            // !active
                            // ?
                            `linear-gradient(-90deg, ${theme.palette.grey[50]} 0%, ${theme.palette.grey[50]} 50%, rgba(0,0,0,0) 100%)`,
                        // : 'none',
                        height: '100%',
                        zIndex: 100000000000
                    }}
                />
                <Typography
                    component="span"
                    sx={{
                        // width: '100%',

                        color: fg,
                        fontWeight: active ? 600 : 400,
                        lineHeight: '1rem',

                        fontSize: subtitle?.length > 0 ? '.75rem' : '1rem',

                        m: 0,
                        p: 0
                    }}
                >
                    {label}
                </Typography>
                <Typography
                    component="span"
                    sx={{
                        fontSize: '1rem',
                        lineHeight: '1.25rem',
                        color: theme.palette.primary.main,
                        fontWeight: active ? 600 : 400,
                        m: 0,
                        p: 0
                    }}
                >
                    {subtitle !== 'undefined' && subtitle}
                </Typography>
            </Box>
            <Box
                sx={{
                    width: '12px',
                    height: '3.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                    // transition: 'all .1s ease-in-out',
                    // '& svg': {
                    //     transition: 'all .1s ease-in-out'
                    // }
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height={56} width={12} viewBox="0 0 12 56">
                    <path
                        fill={active === false ? theme.palette.primary.light : theme.palette.grey[50]}
                        d="M0 2.5 10.9 28 0 53.5V56l12-28L0 0z"
                    />
                    <path fill={bg} d="M0 2.5v51L10.9 28z" />
                </svg>
            </Box>
        </Button>
    );
};

DividerButton.propTypes = {
    index: PropTypes.number,
    label: PropTypes.string,
    active: PropTypes.bool,
    subtitle: PropTypes?.string,
    route: PropTypes.string
};

export default DividerButton;
