import PropTypes from 'prop-types';

// material-ui
import { List, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import { UserContext } from 'context/UserContext';
import { useContext, useEffect, useState } from 'react';
import NavCollapse from '../NavCollapse';
import NavItem from '../NavItem';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();
    const [userContext, setUserContext] = useContext(UserContext);
    const [role, setRole] = useState(userContext.user?.identity?.Ruolo);
    useEffect(() => {
        setRole(userContext.user?.identity?.Ruolo);
    }, [userContext, role]);
    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                return <NavItem key={menu.id} visibilityHandler={role?.livello} policyHandler={role?.interno} item={menu} level={1} />;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
                sx={{
                    display: item.accessLevel.includes(role?.livello) && role?.interno === item?.internal ? 'block' : 'none',
                    padding: '0',
                    marginTop: '0'
                }}
                // subheader={
                //     item.title && (
                //         <Typography variant="caption" sx={{ px: '1rem', ...theme.typography.menuCaption }} display="block" gutterBottom>
                //             {item.title}
                //             {item.caption && (
                //                 <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                //                     {item.caption}
                //                 </Typography>
                //             )}
                //         </Typography>
                //     )
                // }
            >
                {items}
            </List>

            {/* group divider */}
            {/* <Divider
                sx={{
                    mt: 0.25,
                    mb: 1.25,
                    height: '2px',
                    background: theme.palette.grey[50],
                    display: item.accessLevel <= visibilityHandler ? 'block' : 'none'
                }}
            /> */}
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
